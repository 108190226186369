import { createApp } from 'vue'
import { createWebHistory, createRouter } from 'vue-router'
import App from './App.vue'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import Home from './components/Home.vue'
import Contact from './components/Contact.vue'
import About from './components/About.vue'
import ProjectsList from './components/ProjectsList.vue'
import CertsAndCoursesList from './components/CertsAndCoursesList.vue'
import TagsList from './components/TagsList.vue'
import DetailP from './components/DetailP.vue'
import DetailC from './components/DetailC.vue'

const routes = [
    {
      path: "/",
      name: "Jahaziel Alvarez",
      component: Home,
      meta: {
        header: 0
      }
    },
    {
      path: "/contact",
      name: "Jahaziel A. — Contacto",
      component: Contact,
      meta: {
        header: 0
      }
    },
    {
      path: "/about",
      name: "Jahaziel A. — Acerca de mí",
      component: About,
      meta: {
        header: 0
      }
    },
    {
      path: "/portfolio",
      name: "Jahaziel A. — Portafolio",
      component: ProjectsList,
      meta: {
        header: 1
      }
    },
    {
      path: "/certsandcourses",
      name: "Jahaziel A. — Cursos y certificaciones",
      component: CertsAndCoursesList,
      meta: {
        header: 1
      }
    },
    {
      path: "/projects/detail/:id_pro",
      name: "Jahaziel A. — Detalles de proyecto",
      component: DetailP,
      meta: {
        header: 1
      }
    },
    {
      path: "/certsandcourses/detail/:id_cc",
      name: "Jahaziel A. — Detalles de cursos y certificaciones",
      component: DetailC,
      meta: {
        header: 1
      }
    },
    {
      path: "/tags/:id_cc",
      name: "Jahaziel A. — Tags",
      component: TagsList,
      meta: {
        header: 1
      },
      props(route) {
        return {  tag: route.query.tag }
      }
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
  });

  router.beforeEach((to, from, next) => {
    document.title = to.name
    next()
  })

 createApp(App).use(router).mount('#app')
