<template>
    <div class="mt-3 mt-md-0">
        <h1>Proyectos</h1>
        <div class="d-lg-none">
            <div v-for="p in projects" v-bind:key="p">
                <router-link :to="'/projects/detail/' + p.id_pro ">
                    <div class="card text-white bg-dark mb-3">
                        <div class="card-body">
                            <h5 class="card-title text-white">{{ p.name_pro }}</h5>
                            <p class="card-text text-white">{{ p.short_desc_pro }}</p>
                            <img :src="'https://api.jahaziel-alvarez.rocks' + p.image_pro" class="img-fluid img-thumbnail">
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                </router-link>
            </div>
        </div>
        
        <div class="d-none d-lg-block">
            <div class="row row-cols-1 row-cols-md-2 g-4 mt-md-0">
                <div v-for="p in projects" v-bind:key="p">
                    <router-link :to="'/projects/detail/' + p.id_pro " class="routerlinkdark">
                        <div class="card text-white bg-dark mb-3 projectsize mx-auto sd flex">
                            <div class="card-body">
                                <h5 class="card-title text-white">{{ p.name_pro }}</h5>
                                <p class="card-text text-white">{{ p.short_desc_pro }}</p>
                                <img :src="'https://api.jahaziel-alvarez.rocks' + p.image_pro" class="img-fluid img-thumbnail projectimagesize sd">
                            </div>
                        </div>
                    </router-link>
                    <br/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {

    created () {
        this.findProjects();
    },
    data() {
        return {
            projects: ["1", "2", "3"]
        };
    },
    methods: {
        findProjects: function(){
            fetch('https://api.jahaziel-alvarez.rocks/api/project/?format=json')
            .then(res => res.json())
            .then(res => this.projects = res)
        }
    },
}
</script>

<style>
.projectimagesize{
    max-width: 300px;
    max-height: 150px;
}

.projectsize{
    width: 600px;
    height: 340px;
}

.centerindiv{
    text-align: center;
}

.projectimagesize:hover{
    max-width: 100%;
    max-height: 100%;
}

.routerlinkdark{
    color: grey;
    text-decoration: none;
}

.routerlinkdark:hover{
    color: white;
}

.monospacefont{
    font-family: monospace;
}
</style>