<template>
    <div>
        <div class="mt-3 mt-md-0 text-white pre-formatted">
            <h1>{{ certsandcourses.name_cc }}</h1>
            <div class="card text-white bg-dark mb-3">
                <div class="card-body">
                    <h5 class="card-text text-white justifyindiv">{{ certsandcourses.long_desc_cc }}</h5>
                    <a :href="certsandcourses.url_cc" target="_blank" rel="noreferrer noopener">
                        <img :src="'https://api.jahaziel-alvarez.rocks' + certsandcourses.image_cc" class="img-fluid img-thumbnail">
                    </a>
                    <br/>
                    <div class="justjustify">
                        <router-link v-for="t in tags" v-bind:key="t" class="btn btn-light btn-sm mb-2 mt-2" style="margin-right: 5px" :to="{path: '/tags/'+t.tag_id_tcc_id, query: {tag: t.name_tags}}"> {{ t.name_tags }}</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {

    created () {
        this.find();
    },
    data() {
        return {
            certsandcourses: Object,
            tags: Object
        };
    },
    methods: {
        find: function(){
            fetch('https://api.jahaziel-alvarez.rocks/api/certsandcourses/'+this.$route.params.id_cc+'/?format=json')
            .then(res => res.json())
            .then(res => {
                this.certsandcourses = res
                this.findTags(this.certsandcourses.id_cc)
            })
        },
        findTags: function(id_cc){
            fetch('https://api.jahaziel-alvarez.rocks/api/tagcatcc/'+id_cc+'/tags/?format=json')
            .then(res => res.json())
            .then(res => this.tags = res)
        }
    },
}
</script>

<style>
.projectimagesize{
    max-width: 300px;
    max-height: 150px;
}

.projectsize{
    width: 600px;
    height: 340px;
}

.centerindiv{
    text-align: center;
}

.justifyindiv{
    text-align: justify;
    margin-left:50px;
    margin-right:50px;
}

.justjustify{
    text-align: justify;
    margin-left:23px;
}

.projectimagesize:hover{
    max-width: 100%;
    max-height: 100%;
}

.pre-formatted {
  white-space: pre-wrap;
  border: 1px dotted;
  padding: 1rem;
}
</style>