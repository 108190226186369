<template>
    <div class="mt-3 mt-md-0">
        <h1>Cursos y certificaciones</h1>
        <div class="d-lg-none">
            <div v-for="c in certsandcourses" v-bind:key="c">
                <router-link :to="'/certsandcourses/detail/' + c.id_cc" class="routerlinkdark">
                    <div class="card text-white bg-dark mb-5">
                        <div class="card-body">
                            <h5 class="card-title text-white">{{ c.name_cc }}</h5>
                            <p class="card-text text-white">{{ c.short_desc_cc }}</p>
                            <img :src="'https://api.jahaziel-alvarez.rocks' + c.image_cc" class="img-fluid img-thumbnail">
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                </router-link>
            </div>
        </div>
        
        <div class="d-none d-lg-block">
            <div class="row row-cols-1 row-cols-md-2 g-4 mt-md-0">
                <div v-for="c in certsandcourses" v-bind:key="c">
                    <router-link :to="'/certsandcourses/detail/' + c.id_cc" class="routerlinkdark">
                        <div class="card text-white bg-dark mb-5 projectsize mx-auto sd flex mt-5">
                            <div class="card-body">
                                <h5 class="card-title text-white">{{ c.name_cc }}</h5>
                                <p class="card-text text-white">{{ c.short_desc_cc }}</p>
                                <img :src="'https://api.jahaziel-alvarez.rocks' + c.image_cc" class="img-fluid img-thumbnail projectimagesize sd">
                            </div>
                        </div>
                        <br/>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {

    created () {
        this.findCC();
    },
    data() {
        return {
            certsandcourses: ["1", "2", "3"]
        };
    },
    methods: {
        findCC: function(){
            fetch('https://api.jahaziel-alvarez.rocks/api/certsandcourses/?format=json')
            .then(res => res.json())
            .then(res => this.certsandcourses = res)
        }
    },
}
</script>

<style>
.projectimagesize{
    max-width: 300px;
    max-height: 150px;
}

.projectsize{
    width: 600px;
    height: 340px;
    margin-left:0;
    margin-right:0;
}

.centerindiv{
    text-align: center;
}

.projectimagesize:hover{
    max-width: 100%;
    max-height: 100%;
}

.marginzero{
    margin-left:0;
    margin-right:0;
}

.routerlinkdark{
    color: grey;
    text-decoration: none;
}

.routerlinkdark:hover{
    color: white;
}

</style>