<template>
    <div class="min-vh-100 min-vw-100 p-5">
        <div class="container monospacefont">
            <div class="row">
                <div class="col-sm">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="white" class="bi bi-arrow-90deg-left" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4z"/>
                    </svg>
                    <router-link to="/" class="routerlinkdark hugefontsize"><i class="bi bi-arrow-90deg-left"></i> <b>Jahaziel Alvarez</b></router-link>
                    <br/>
                    <br/>
                    <img src="../assets/Profile.jpg" alt="Perfil" class="rounded-circle d-none d-sm-block">
                    <img src="../assets/Profile.jpg" alt="Perfil" class="rounded-circle mobileview d-sm-none">
                </div>
                <div class="col-sm">
                    <br/>
                    <br/>
                    <div class="justifyindiv bigfontsize text-white">
                        Hola, soy un pasante de ingeniería en computación con interés en el desarrollo de software, Big Data y Machine Learning.
                        <br/>
                        <br/>
                        
                    </div>
                </div>
                <div class="bigfontsize text-white mt-5">
                    A continuación dejo mi Curriculum Vitae:
                </div>
                <iframe src="https://drive.google.com/file/d/1Mp6Apv6RE8xrtJJSKHvwVYD0_9z6sX6q/preview" width="700" height="480" allow="autoplay" class="mt-2"></iframe>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    
}
</script>

<style>
.routerlinkdark{
    color: grey;
    text-decoration: none;
}

.routerlinkdark:hover{
    color: white;
}

.mobileview{
    max-width: 100%;
    max-height: 100%;
}

.justifyindiv{
    text-align: justify;
    margin-left:50px;
    margin-right:50px;
}

.bigfontsize{
    font-size: 30px;
}

.hugefontsize{
    font-size: 40px;
}
</style>