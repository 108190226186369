<template>
    <div>
        <nav class="navbar navbar-expand-lg  navbar-dark bg-dark .hidden-md">
            <div class="container-fluid">
                <a class="navbar-brand" href="/">Jahaziel Alvarez</a>

                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                
                <div class="collapse navbar-collapse" id="navbarNav">
                    <ul class="navbar-nav">
                        <!--
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a class="dropdown-item" v-for="p in projects" v-bind:key="p.id_pro" :href="'/projects/detail/' + p.id_pro">{{ p.name_pro }}</a></li>
                        </ul>
                        -->
                        <li class="nav-item dropdown">
                            <router-link class="nav-link dropdown-toggle" to="/portfolio" id="navbarDropdown">
                                Portafolio
                            </router-link>

                            <!--
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li><router-link class="dropdown-item" v-for="p in projects" v-bind:key="p.id_pro" :to="'/projects/detail/' + p.id_pro">{{ p.name_pro }}</router-link></li>
                            </ul>
                            -->

                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a class="dropdown-item" v-for="p in projects" v-bind:key="p.id_pro" :href="'/projects/detail/' + p.id_pro">{{ p.name_pro }}</a></li>
                            </ul>
                        </li>
                        
                        <li class="nav-item dropdown">
                            <router-link class="nav-link dropdown-toggle" to="/certsandcourses" id="navbarDropdown">
                                Cursos y certificaciones
                            </router-link>

                            <!--
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><router-link class="dropdown-item" v-for="c in certsandcourses" v-bind:key="c.id_cc" :to="'/certsandcourses/detail/' + c.id_cc ">{{ c.name_cc }}</router-link></li>
                            </ul>
                            -->

                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a class="dropdown-item" v-for="c in certsandcourses" v-bind:key="c.id_cc" :href="'/certsandcourses/detail/' + c.id_cc ">{{ c.name_cc }}</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link active" aria-current="page" href="/Contact">Contacto</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link active" aria-current="page" href="/About">Acerca de mí</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
export default {
    props: ['shouldRender'],

    created () {
        this.findProjects();
        this.findCertsAndCourses();
    },

    data() {
        return {
            projects: ["1", "2", "3"],
            certsandcourses: ["1", "2", "3"]
        };
    },
    methods: {
        findProjects: function(){
            fetch("https://api.jahaziel-alvarez.rocks/api/project/?format=json")
            .then(res => res.json())
            .then(res => this.projects = res)
        },
        findCertsAndCourses: function(){
            fetch("https://api.jahaziel-alvarez.rocks/api/certsandcourses/?format=json")
            .then(res => res.json())
            .then(res => this.certsandcourses = res)
        },
    },
}
</script>

<style >

.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
 }

</style>