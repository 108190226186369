<template>
    <div class="min-vh-100 min-vw-100 p-5">
        <div class="container monospacefont">
            <div class="row">
                <div class="col-sm">
                    <h1 class="text-white justifyindiv"><b>Jahaziel Alvarez</b></h1>
                    <br/>
                    <br/>
                    <h5 class="text-white justifyindivnomargin">Pasante de la carrera de Ingeniería en Computación en el Instituto Politécnico Nacional. </h5>
                    <br/>
                    <br/>
                    <router-link to="/portfolio" class="routerlinkdark justifyindiv"><h5>— Portafolio</h5></router-link>
                    <br/>
                    <router-link to="/certsandcourses" class="routerlinkdark justifyindiv"><h5>— Cursos y certificaciones</h5></router-link>
                    <br/>
                    <router-link to="/Contact" class="routerlinkdark justifyindiv"><h5>— Contacto</h5></router-link>
                    <br/>
                    <router-link to="/About" class="routerlinkdark justifyindiv"><h5>— Acerca de</h5></router-link>
                </div>
                <div class="col-sm">
                    <img src="../assets/Profile.jpg" alt="Perfil" class="rounded-circle d-none d-sm-block">
                    <img src="../assets/Profile.jpg" alt="Perfil" class="rounded-circle mobileview d-sm-none">
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    
}
</script>

<style>
.routerlinkdark{
    color: grey;
    text-decoration: none;
}

.routerlinkdark:hover{
    color: white;
}

.mobileview{
    max-width: 100%;
    max-height: 100%;
}

.justifyindiv{
    text-align: justify;
    margin-left:50px;
    margin-right:50px;
}

.justifyindivnomargin{
    text-align: justify;
}
</style>