<template>
    <div class="mt-3 mt-md-0">
        <h1>{{tagname}}</h1>

        <div v-if="projects.length > 0" class="text-white">
            <h2> Proyectos </h2>

            <div class="d-lg-none">
                <div v-for="p in projects" v-bind:key="p">
                    <router-link :to="'/projects/detail/' + p.id_pro ">
                        <div class="card text-white bg-dark mb-3">
                            <div class="card-body">
                                <h5 class="card-title text-white">{{ p.name_pro }}</h5>
                                <p class="card-text text-white">{{ p.short_desc_pro }}</p>
                                <img :src="'http://143.198.230.82' + p.image_pro" class="img-fluid img-thumbnail">
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                    </router-link>
                </div>
            </div>
            
            <div class="d-none d-lg-block">
                <div class="row row-cols-1 row-cols-md-2 g-4 mt-md-0">
                    <div v-for="p in projects" v-bind:key="p">
                        <router-link :to="'/projects/detail/' + p.id_pro " class="routerlinkdark">
                            <div class="card text-white bg-dark mb-3 projectsize mx-auto sd flex">
                                <div class="card-body">
                                    <h5 class="card-title text-white">{{ p.name_pro }}</h5>
                                    <p class="card-text text-white">{{ p.short_desc_pro }}</p>
                                    <img :src="'http://143.198.230.82' + p.image_pro" class="img-fluid img-thumbnail projectimagesize sd">
                                </div>
                            </div>
                        </router-link>
                        <br/>
                    </div>
                </div>
            </div>
        </div>
        
        <div v-if="certsandcourses.length > 0" class="text-white">
            <h2> Cursos y certificaciones </h2>
            <div class="d-lg-none">
                <div v-for="c in certsandcourses" v-bind:key="c">
                    <router-link :to="'/certsandcourses/detail/' + c.id_cc ">
                        <div class="card text-white bg-dark mb-3">
                            <div class="card-body">
                                <h5 class="card-title text-white">{{ c.name_cc }}</h5>
                                <p class="card-text text-white">{{ c.short_desc_cc }}</p>
                                <img :src="'http://143.198.230.82' + c.image_cc" class="img-fluid img-thumbnail">
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                    </router-link>
                </div>
            </div>
            
            <div class="d-none d-lg-block">
                <div class="row row-cols-1 row-cols-md-2 g-4 mt-md-0">
                    <div v-for="c in certsandcourses" v-bind:key="c">
                        <router-link :to="'/certsandcourses/detail/' + c.id_cc " class="routerlinkdark">
                            <div class="card text-white bg-dark mb-3 projectsize mx-auto sd flex">
                                <div class="card-body">
                                    <h5 class="card-title text-white">{{ c.name_cc }}</h5>
                                    <p class="card-text text-white">{{ c.short_desc_cc }}</p>
                                    <img :src="'http://143.198.230.82' + c.image_cc" class="img-fluid img-thumbnail projectimagesize sd">
                                </div>
                            </div>
                        </router-link>
                        <br/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {

    created () {
        this.find();
        this.tagName();
    },
    data() {
        return {
            certsandcourses: ["1", "2", "3"],
            projects: ["1", "2", "3"],
            tagname: Object
        };
    },
    methods: {
        find: function(){
            fetch('https://api.jahaziel-alvarez.rocks/api/tagcatcc/'+this.$route.params.id_cc+'/certsandcourses/?format=json')
            .then(res => res.json())
            .then(res => this.certsandcourses = res)
            fetch('https://api.jahaziel-alvarez.rocks/api/tagcatpro/'+this.$route.params.id_cc+'/projects/?format=json')
            .then(res => res.json())
            .then(res => this.projects = res)
        },
        tagName: function(){
            this.tagname = this.$route.query.tag
        }
    },
}
</script>

<style>
.projectimagesize{
    max-width: 300px;
    max-height: 150px;
}

.projectsize{
    width: 600px;
    height: 340px;
}

.centerindiv{
    text-align: center;
}

.projectimagesize:hover{
    max-width: 100%;
    max-height: 100%;
}

.routerlinkdark{
    color: grey;
    text-decoration: none;
}

.routerlinkdark:hover{
    color: white;
}

.monospacefont{
    font-family: monospace;
}
</style>