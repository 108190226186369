<template>
    <div>
        <div class="mt-3 mt-md-0 text-white pre-formatted">
            <h1 class="centerindiv">{{ project.name_pro }}</h1>
            <div class="card text-white bg-dark mb-3 justifyindiv">
                <div class="card-body">
                    <h5 class="card-text text-white">{{ project.long_desc_pro }}</h5>
                    <div class="embed-responsive embed-responsive-16by9"  v-if="project.url_pro && youtubeFlag">
                        <iframe width=100% height=800px  class="embed-responsive-item" :src="project.url_pro" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    <div v-else-if="project.url_pro && !youtubeFlag">
                        <a :href="project.url_pro" style="text-decoration:none" target="_blank" rel="noreferrer noopener"><h5>Link al proyecto</h5></a>
                    </div>
                    <div v-else-if="!project.url_pro">
                        <img :src="'https://api.jahaziel-alvarez.rocks' + project.image_pro" class="img-fluid img-thumbnail">
                    </div>
                    <br/>
                    <router-link v-for="t in tags" v-bind:key="t" class="btn btn-light btn-sm" style="margin-right: 5px" :to="{path: '/tags/'+t.tag_id_tcp_id, query: {tag: t.name_tags}}"> {{ t.name_tags }}</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {

    created () {
        this.find();
        this.isYoutube();
    },
    data() {
        return {
            project: Object,
            youtubeFlag: Boolean,
            tags: Object
        };
    },
    methods: {
        find: function(){
            fetch('https://api.jahaziel-alvarez.rocks/api/project/'+this.$route.params.id_pro+'/?format=json')
            .then(res => res.json())
            .then(res => {
                this.project = res
                this.findTags(this.project.id_pro)
            })
        },
        isYoutube: function(){
            try {
                if (this.project.url_pro.includes('youtube')){
                    this.youtubeFlag = true
                }else{
                    this.youtubeFlag = false
                }
            } catch (error) {
                console.log("Empty url")
            }
            
        },
        findTags: function(id_pro){
            fetch('https://api.jahaziel-alvarez.rocks/api/tagcatpro/'+id_pro+'/tags/?format=json')
            .then(res => res.json())
            .then(res => this.tags = res)
        }
    },
}
</script>

<style>
.projectimagesize{
    max-width: 300px;
    max-height: 150px;
}

.projectsize{
    width: 600px;
    height: 340px;
}

.centerindiv{
    text-align: center;
    margin-left:50px;
    margin-right:50px;
}

.justifyindiv{
    text-align: justify;
    margin-left:50px;
    margin-right:50px;
}

.projectimagesize:hover{
    max-width: 100%;
    max-height: 100%;
}

.pre-formatted {
  white-space: pre-wrap;
  border: 1px dotted;
  padding: 1rem;
}
</style>