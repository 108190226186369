<template>
    <div class="min-vh-100 min-vw-100 p-5">
        <div class="container">
            <div class="row">
                <div class="col-sm">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="white" class="bi bi-arrow-90deg-left" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4z"/>
                    </svg>
                    <router-link to="/" class="routerlinkdark hugefontsize "><i class="bi bi-arrow-90deg-left"></i> Jahaziel Alvarez</router-link>
                    <br/>
                    <br/>
                    <div class="justifyindiv bigfontsize">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
                            <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
                        </svg>
                        <a href="https://www.linkedin.com/in/jahaziel-alvarez/" class="routerlinkdark"><i class="bi bi-linkedin"></i> LinkedIn</a>
                        <br/>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z"/>
                        </svg>
                        <a href="mailto:jahaziel.alvarez@pm.me"  target="_blank" class="routerlinkdark"><i class="bi bi-envelope"></i> jahaziel.alvarez@pm.me</a>
                        
                    </div>
                </div>
                <div class="col-sm">
                    <img src="../assets/Profile.jpg" alt="Perfil" class="rounded-circle d-none d-sm-block">
                    <img src="../assets/Profile.jpg" alt="Perfil" class="rounded-circle mobileview d-sm-none">
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    
}
</script>

<style>
.routerlinkdark{
    color: grey;
    text-decoration: none;
}

.routerlinkdark:hover{
    color: white;
}

.mobileview{
    max-width: 100%;
    max-height: 100%;
}

.justifyindiv{
    text-align: justify;
    margin-left:50px;
    margin-right:50px;
}

.bigfontsize{
    font-size: 30px;
}

.hugefontsize{
    font-size: 40px;
}
</style>