<template>
  <div id='app' class="min-vh-100 min-vw-100 sd">
    <div v-if="$route.meta.header === 1" class="format">
      <Header/>
      <div class="mb-1">
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import Header from './components/Header.vue'

export default {
  name: 'App',
  components: {
    Header
  }
}
</script>

<style>

.format {
  font-family: monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color:black;
  background-size: 100% 100%;
}

#app {
  font-family: "Segoe UI", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-size: 100% 100%;
  background-color:black;
}

.routerlinkdark{
    color: grey;
    text-decoration: none;
}

.routerlinkdark:hover{
    color: white;
}

</style>
